// Imports
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api, api_post } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import Loading from '../components/loading';
import PageHeader from '../components/page-header';

const ProjectDetail = () => {
    const { id } = useParams(); // Get project ID from URL
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // New state to track editing mode
    const [formData, setFormData] = useState({}); // State to hold form data during editing
    const [saving, setSaving] = useState(false); // State to indicate saving in progress

    useEffect(() => {
        fetchProject();
    }, [id]);

    const fetchProject = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/project/${id}`
            );
            setProject(result);
            setFormData(result); // Initialize formData with fetched project data
        } catch (err) {
            setError('Error loading project data');
            toast.error('Failed to load project details');
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setFormData(project); // Reset form data to original project data
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            const updatedProject = { ...formData };
            // Send updated data to the API
            await api_post(
                `${process.env.REACT_APP_API_URL}/admin/project/${id}`,
                updatedProject
            );
            toast.success('Project updated successfully');
            setProject(updatedProject);
            setIsEditing(false);
        } catch (err) {
            toast.error('Failed to update project');
        } finally {
            setSaving(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;
    if (!project) return null;

    const {
        name,
        location,
        description,
        customer,
        projectOwner,
        mainContact,
        mainContactEmail,
        startDate,
        totalPrice,
        deposit,
        paidToDate,
        costsIncurredToDate,
        totalEstimatedCosts,
        completedDate,
        status,
    } = isEditing ? formData : project;

    const renderValue = (value, isCurrency = false, isDate = false) => {
        if (value) {
            if (isCurrency) return `$${Number(value).toLocaleString()}`;
            if (isDate) return new Date(value).toLocaleDateString();
            return value;
        } else {
            return <span className="text-yellow-500">Missing</span>;
        }
    };

    const renderInput = (name, value, type = 'text', isCurrency = false, isDate = false) => {
        return (
            <input
                type={type}
                name={name}
                value={value || ''}
                onChange={handleInputChange}
                className="border p-2 w-full rounded"
            />
        );
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Project Detail'} />
            <div className="container mx-auto my-10">
                <div className="mx-6">
                    {/* Project Information Section */}
                    <div className="bg-gray-50 border rounded py-4 mb-8">
                        <h2 className="text-2xl font-semibold mb-4 ml-4 underline">Project Information</h2>
                        <div className="grid gap-y-2">
                            {/* Each row */}
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Name:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('name', name)
                                    ) : (
                                        renderValue(name)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Location:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('location', location)
                                    ) : (
                                        renderValue(location)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Description:</span>
                                <span>
                                    {isEditing ? (
                                        <textarea
                                            name="description"
                                            value={description || ''}
                                            onChange={handleInputChange}
                                            className="border p-2 w-full rounded"
                                        />
                                    ) : (
                                        renderValue(description)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Customer:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('customer', customer)
                                    ) : (
                                        renderValue(customer)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Project Owner:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('projectOwner', projectOwner)
                                    ) : (
                                        renderValue(projectOwner)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Main Contact:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('mainContact', mainContact)
                                    ) : (
                                        renderValue(mainContact)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Main Contact Email:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('mainContactEmail', mainContactEmail, 'email')
                                    ) : (
                                        renderValue(mainContactEmail)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Start Date:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('startDate', startDate, 'date')
                                    ) : (
                                        renderValue(startDate, false, true)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Completed Date:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('completedDate', completedDate, 'date')
                                    ) : (
                                        renderValue(completedDate, false, true)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Status:</span>
                                <span>
                                    {isEditing ? (
                                        <select
                                            name="status"
                                            value={status || ''}
                                            onChange={handleInputChange}
                                            className="border p-2 w-full rounded"
                                        >
                                            <option value="">Select Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="active">Active</option>
                                            <option value="completed">Completed</option>
                                            <option value="archived">Archived</option>
                                        </select>
                                    ) : (
                                        <span className={`capitalize`}>{status}</span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Financial Information Section */}
                    <div className="bg-gray-50 border rounded py-6 mb-8">
                        <h2 className="text-2xl font-semibold mb-4 ml-4 underline">Financial Information</h2>
                        <div className="grid gap-y-2">
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Total Price:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('totalPrice', totalPrice, 'number')
                                    ) : (
                                        renderValue(totalPrice, true)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Deposit:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('deposit', deposit, 'number')
                                    ) : (
                                        renderValue(deposit, true)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Paid to Date:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('paidToDate', paidToDate, 'number')
                                    ) : (
                                        renderValue(paidToDate, true)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Costs Incurred to Date:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('costsIncurredToDate', costsIncurredToDate, 'number')
                                    ) : (
                                        renderValue(costsIncurredToDate, true)
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Total Estimated Costs:</span>
                                <span>
                                    {isEditing ? (
                                        renderInput('totalEstimatedCosts', totalEstimatedCosts, 'number')
                                    ) : (
                                        renderValue(totalEstimatedCosts, true)
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Actions Section */}
                    <div className="mt-6 flex space-x-4">
                        {isEditing ? (
                            <>
                                <button
                                    onClick={handleSave}
                                    className="bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                                    disabled={saving}
                                >
                                    {saving ? 'Saving...' : 'Save'}
                                </button>
                                <button
                                    onClick={handleCancelEdit}
                                    className="bg-gray-500 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                                    disabled={saving}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={handleEditClick}
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                                >
                                    Edit Project
                                </button>
                                <button
                                    onClick={() => navigate('/admin/project')}
                                    className="bg-gray-500 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                                >
                                    Back to Projects
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectDetail;
