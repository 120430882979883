import { useRole } from '../hooks/useRole';
import { Navigate, Outlet } from 'react-router-dom';

const DEFAULT_REDIRECT_URL = '/';

const RoleWrapper = ({
  requiredRole,
  notAllowedUrl = DEFAULT_REDIRECT_URL,
}) => {
  const { isAllowed } = useRole(requiredRole);
  return isAllowed ? <Outlet /> : <Navigate to={notAllowedUrl} />;
};

export default RoleWrapper;
