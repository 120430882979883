// Imports
import { useState, useEffect } from 'react';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import Table from '../components/table';

const EXPORT_TABLE_COLUMNS = [
    'Business ID',
    'Source',
    'Date',
];

const DOCUMENTS_TABLE_COLUMNS = [
    {
        Header: 'Business ID',
        accessor: 'businessId',
    },
    {
        Header: 'Source',
        accessor: 'source',
        Cell: ({ value }) => value || 'N/A',
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'N/A'),
    },
    {
        Header: 'Actions',
        accessor: 'actions',
    },
];

const FileActionsCell = ({ signedUrl, fileName }) => {
    const [isHovered, setIsHovered] = useState(false);

    const isImage = () => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const extension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };

    return (
        <div
            className="relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <a
                href={signedUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
            >
                Download
            </a>
            {isHovered && isImage() && (
                <div className="absolute z-10 w-64 h-64 mt-2 bg-primary border border-gray-300 shadow-lg">
                    <img
                        src={signedUrl}
                        alt="Preview"
                        className="w-full h-full object-contain"
                    />
                </div>
            )}
        </div>
    );
};

const AdminDocumentsList = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/documents/all`
            );
            setDocuments(result);
        } catch (err) {
            setError('Error loading data');
            toast.error('Failed to load documents');
        } finally {
            setLoading(false);
        }
    };

    if (loading || !documents || documents.length === 0) return <Loading />;
    if (error) return <div>{error}</div>;

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Documents Management'} />
            <div className="flex">
                <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
                    <Table
                        columns={DOCUMENTS_TABLE_COLUMNS}
                        exportColumns={EXPORT_TABLE_COLUMNS}
                        data={documents.map((doc) => ({
                            businessId: doc.sourceData?.businessId || 'N/A',
                            source: doc.sourceData?.source || 'N/A',
                            date: doc.date
                                ? new Date(doc.date.$date || doc.date).toLocaleDateString()
                                : 'N/A',
                            actions: (
                                <FileActionsCell
                                    key={doc._id}
                                    signedUrl={doc.signedUrl}
                                    fileName={doc.key}
                                />
                            ),
                        }))}
                        exportData={documents.map((doc) => ({
                            'Business ID': doc.sourceData?.businessId || 'N/A',
                            'Source': doc.sourceData?.source || 'N/A',
                            'Date': doc.date
                                ? new Date(doc.date.$date || doc.date).toLocaleDateString()
                                : 'N/A',
                        }))}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminDocumentsList;
