import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api, api_post } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import { getStatusClass } from '../utils';
import AdminProjectsTable from '../components/admin-projects-table';
import {
    CogIcon,
    FolderIcon,
    IdentificationIcon,
    StarIcon,
} from '@heroicons/react/24/outline';

const CreditApplicationDetail = () => {
    const { id } = useParams();
    const [application, setApplication] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [projects, setProjects] = useState([]);
    const [newCreditLine, setCreditLine] = useState(0);

    // State variables for QuickBooks company info
    const [companyInfo, setCompanyInfo] = useState(null);
    const [companyInfoLoading, setCompanyInfoLoading] = useState(false);

    useEffect(() => {
        fetchApplication();
    }, [id]);

    const fetchApplication = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/credit-application/${id}`
            );
            setApplication(result);
            setCreditLine(result.creditLine);
            if (
                result.applicationData &&
                result.applicationData.quickbooksAuth &&
                result.applicationData.quickbooksAuth.companyInfo &&
                result.applicationData.quickbooksAuth.companyInfo.CompanyInfo
            ) {
                setCompanyInfo(
                    result.applicationData.quickbooksAuth.companyInfo.CompanyInfo
                );
            }

        } catch (err) {
            setError('Error loading application data');
            toast.error('Failed to load application details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (application && application.business) {
            fetchProjects(application.business);
        }
    }, [application]);

    const fetchProjects = async (businessId) => {
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/project/${businessId}/business`
            );
            setProjects(result);
        } catch (err) {
            toast.error('Failed to load projects');
        }
    };
    const handleApprove = async () => {
        try {
            const updatedApplication = {
                ...application,
                status: 'verified',
                verifiedDate: new Date(),
            };

            await api_post(
                `${process.env.REACT_APP_API_URL}/credit-application/`,
                updatedApplication
            );
            toast.success('Application verified');
            setApplication(updatedApplication);
        } catch (err) {
            toast.error('Failed to verify application');
        }
    };

    const handleReject = async () => {
        try {
            const updatedApplication = {
                ...application,
                status: 'rejected',
                verifiedDate: 'N/A',
            };
            await api_post(
                `${process.env.REACT_APP_API_URL}/credit-application/`,
                updatedApplication
            );
            toast.success('Application rejected');
            setApplication(updatedApplication);
        } catch (err) {
            toast.error('Failed to reject application');
        }
    };

    const handleUpdateCreditLine = async () => {
        try {
            const updatedApplication = {
                ...application,
                creditLine: newCreditLine,
            };
            await api_post(
                `${process.env.REACT_APP_API_URL}/credit-application/`,
                updatedApplication
            );
            toast.success('Credit line updated');
            setApplication(updatedApplication);
        } catch (err) {
            toast.error('Failed to update credit line');
        }
    };

    const handleDownloadDocument = async (key) => {
        const response = await api(
            `${process.env.REACT_APP_API_URL}/document/download-url?key=${key}`
        );
        const link = document.createElement('a');
        link.href = response.url;
        link.setAttribute('download', response.url);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const renderFile = (fileData, label) => {
        if (fileData && fileData.url) {
            return (
                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                    <span className="font-medium">{label}:</span>
                    <span>
                        <button
                            onClick={async () =>
                                await handleDownloadDocument(fileData.key)
                            }
                            className="text-blue-500 underline"
                        >
                            Download File
                        </button>
                    </span>
                </div>
            );
        } else {
            return (
                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                    <span className="font-medium">{label}:</span>
                    <span className="text-yellow-500">Missing</span>
                </div>
            );
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;
    if (!application) return null;

    const {
        applicationData = {},
        createdAt,
        updatedAt,
        owner,
        business,
        status,
        verifiedDate,
    } = application;

    const {
        averageProjectValuePerMonth,
        bankAccountFile,
        companyAddress,
        companyLegalName,
        dateOfIncorporation,
        einNumber,
        monthlyDebtPayment,
        monthlyRevenue,
        phoneNumber,
        projectManagementFile,
        quickbooksFile,
        typesOfProjectsYouWorkOn,
        website,
    } = applicationData;

    const renderValue = (value, isCurrency = false, isDate = false) => {
        if (value && !isDate) {
            return isCurrency ? `$${value}` : value;
        } else if (value && isDate) {
            return new Date(value).toLocaleString();
        } else {
            return <span className="text-yellow-500">Missing</span>;
        }
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader
                headline={'Account Application Detail'}
                showBackButton="true"
            />
            <div className="container mx-auto my-10">
                <div className="mx-6">
                    {/* Overview Section */}
                    <div className="bg-primary shadow-sm border rounded-xl p-6 mb-8">
                        <h2 className="text-3xl font-medium mb-4 flex items-center">
                            <IdentificationIcon className="w-8 h-8 mr-2" />
                            Overview
                        </h2>
                        <div className="grid gap-y-2 border rounded-md">
                            {/* Each row */}
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Company Legal Name:
                                </span>
                                <span>{renderValue(companyLegalName)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Company Address:
                                </span>
                                <span>{renderValue(companyAddress)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Phone Number:
                                </span>
                                <span>{renderValue(phoneNumber)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Website:</span>
                                <span>{renderValue(website)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Types of Projects:
                                </span>
                                <span>
                                    {renderValue(typesOfProjectsYouWorkOn)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Monthly Revenue:
                                </span>
                                <span>
                                    {renderValue(monthlyRevenue, true)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Average Project Value Per Month:
                                </span>
                                <span>
                                    {renderValue(
                                        averageProjectValuePerMonth,
                                        true
                                    )}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Date of Incorporation:
                                </span>
                                <span>
                                    {renderValue(dateOfIncorporation)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    EIN Number:
                                </span>
                                <span>{renderValue(einNumber)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Monthly Debt Payment:
                                </span>
                                <span>
                                    {renderValue(monthlyDebtPayment, true)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Status:</span>
                                <span
                                    className={`${getStatusClass(
                                        status
                                    )} capitalize`}
                                >
                                    {status}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Verified Date:
                                </span>
                                <span>
                                    {renderValue(verifiedDate, false, true)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4 items-center">
                                <span className="font-medium">
                                    Credit Line:
                                </span>
                                <input
                                    type="number"
                                    value={newCreditLine}
                                    onChange={(e) =>
                                        setCreditLine(e.target.value)
                                    }
                                    className="border rounded px-3 py-2"
                                />
                            </div>
                        </div>
                        <div className="mt-6 flex space-x-4">
                            <button
                                onClick={handleUpdateCreditLine}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                            >
                                Update Credit Line
                            </button>
                        </div>
                    </div>

                    {/* QuickBooks Company Info Section */}
                    {companyInfoLoading && <Loading />}
                    {companyInfo && (
                        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
                            <h2 className="text-3xl font-medium mb-4 flex items-center">
                                <FolderIcon className="w-8 h-8 mr-2" />
                                QuickBooks Company Info
                            </h2>
                            <div className="grid gap-y-2 border rounded-md">
                                {/* Render company info fields */}
                                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                    <span className="font-medium">Company Name:</span>
                                    <span>{renderValue(companyInfo.CompanyName)}</span>
                                </div>
                                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                    <span className="font-medium">Legal Name:</span>
                                    <span>{renderValue(companyInfo.LegalName)}</span>
                                </div>
                                {/* Address */}
                                {companyInfo.CompanyAddr && (
                                    <>
                                        <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                            <span className="font-medium">Address Line 1:</span>
                                            <span>{renderValue(companyInfo.CompanyAddr.Line1)}</span>
                                        </div>
                                        {companyInfo.CompanyAddr.City && (
                                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                                <span className="font-medium">City:</span>
                                                <span>{renderValue(companyInfo.CompanyAddr.City)}</span>
                                            </div>
                                        )}
                                        {companyInfo.CompanyAddr.CountrySubDivisionCode && (
                                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                                <span className="font-medium">State:</span>
                                                <span>
                                                    {renderValue(companyInfo.CompanyAddr.CountrySubDivisionCode)}
                                                </span>
                                            </div>
                                        )}
                                        {companyInfo.CompanyAddr.PostalCode && (
                                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                                <span className="font-medium">Postal Code:</span>
                                                <span>{renderValue(companyInfo.CompanyAddr.PostalCode)}</span>
                                            </div>
                                        )}
                                        {companyInfo.CompanyAddr.Country && (
                                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                                <span className="font-medium">Country:</span>
                                                <span>{renderValue(companyInfo.CompanyAddr.Country)}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                                {/* Additional Fields */}
                                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                    <span className="font-medium">Company Start Date:</span>
                                    <span>{renderValue(companyInfo.CompanyStartDate)}</span>
                                </div>
                                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                    <span className="font-medium">Fiscal Year Start Month:</span>
                                    <span>{renderValue(companyInfo.FiscalYearStartMonth)}</span>
                                </div>
                                <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                    <span className="font-medium">Email:</span>
                                    <span>{renderValue(companyInfo.Email?.Address)}</span>
                                </div>
                                {/* Add more fields as needed */}
                            </div>
                        </div>
                    )}

                    <div className="flex flex-row mb-8 gap-x-8">
                        {/* Files Section */}
                        <div className="bg-primary shadow-sm border rounded-lg p-6 w-1/2">
                            <h2 className="text-3xl font-medium mb-4 flex items-center">
                                <FolderIcon className="w-8 h-8 mr-2" />
                                Files
                            </h2>
                            <div className="my-auto grid gap-y-2 border rounded-md">
                                {/* Render files */}
                                {renderFile(bankAccountFile, 'Bank Account File')}
                                {renderFile(
                                    projectManagementFile,
                                    'Project Management File'
                                )}
                                {renderFile(quickbooksFile, 'QuickBooks File')}
                            </div>
                        </div>

                        {/* Projects Section */}
                        <div className="bg-primary shadow-sm border rounded-lg p-6 w-1/2">
                            <h2 className="text-3xl font-medium mb-4 flex items-center">
                                <CogIcon className="w-8 h-8 mr-2" />
                                Projects
                            </h2>
                            <AdminProjectsTable
                                className="rounded-md overflow-hidden"
                                projects={projects}
                            />
                        </div>
                    </div>

                    {/* Metadata Section */}
                    <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
                        <h2 className="text-3xl font-medium mb-4 flex items-center">
                            <StarIcon className="w-8 h-8 mr-2" />
                            Metadata
                        </h2>
                        <div className="grid gap-y-2 border rounded-md">
                            {/* Each row */}
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Date of Creation:
                                </span>
                                <span>
                                    {renderValue(createdAt, false, true)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Last Updated:
                                </span>
                                <span>
                                    {renderValue(updatedAt, false, true)}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Owner ID:
                                </span>
                                <span>{renderValue(owner)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">
                                    Business ID:
                                </span>
                                <span>{renderValue(business)}</span>
                            </div>
                        </div>
                    </div>

                    {/* Approve and Reject Buttons */}
                    <div className="mt-6 flex space-x-4">
                        <button
                            onClick={handleApprove}
                            className="bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                        >
                            Verified
                        </button>
                        <button
                            onClick={handleReject}
                            className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                        >
                            Rejected
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreditApplicationDetail;
