export default function CheckboxField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  inline,
  onBlur,
  disabled = false,
  small = false,
}) {
  return (
    <div
      className={
        className + (inline ? ' flex place-items-center' : '') + ' mt-2'
      }
    >
      {!inline && (
        <label
          className={`block text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
        >
          {label}
        </label>
      )}
      <div className={inline ? '' : 'mt-1'}>
        <input
          onBlur={onBlur}
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          className={`${inline ? 'w-7 h-7' : 'w-9 h-9'} transition-all block rounded border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none  sm:text-sm sm:leading-6 ${inputClass} bg-primary checked:bg-yellow-500 cursor-pointer ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
        />
      </div>
      {inline && (
        <label
          className={`ml-1 block text-sm font-medium ${labelClass}`}
        >
          {label}
        </label>
      )}
    </div>
  );
}
