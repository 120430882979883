import { api_post, api } from '../api.service';

export const apiCreateTempPassword = async (userId, tempPass) => {
  return await api_post(
    `${process.env.REACT_APP_API_URL}/admin/user/${userId}/password/temporary`,
    { tempPass },
  );
};

export function getUser(userId) {
  return api(`${process.env.REACT_APP_API_URL}/app/user/${userId}`);
}
