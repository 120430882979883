import TextField from './text-field';
import PasswordField from './password-field';
import SelectField from './select-field';

const USER_CREATION_FIELDS = [
  {
    id: 'email',
    label: 'Email',
    required: true,
    component: TextField,
  },
  {
    id: 'password',
    label: 'New Password',
    required: true,
    component: PasswordField,
  },
  {
    id: 'name',
    label: 'Name',
    required: true,
    component: TextField,
  },
  {
    id: 'roles',
    label: 'Role',
    required: true,
    component: SelectField,
    componentProps: {
      optionListName: 'RBAC_roles',
    },
  },
];

const USER_UPDATING_FIELDS = [
  {
    id: 'email',
    label: 'Email',
    required: true,
    component: TextField,
  },
  {
    id: 'password',
    label: 'New Password',
    required: false,
    component: PasswordField,
  },
  {
    id: 'name',
    label: 'Name',
    required: true,
    component: TextField,
  },
  {
    id: 'roles',
    label: 'Role',
    required: true,
    component: SelectField,
    componentProps: {
      optionListName: 'RBAC_roles',
    },
  },
];

const CUSTOMER_USER_CREATION_FORM_FIELDS = [
  {
    title: 'User Information',
    fields: USER_CREATION_FIELDS,
  },
];

const CUSTOMER_USER_UPDATING_FORM_FIELDS = [
  {
    title: 'User Information',
    fields: USER_UPDATING_FIELDS,
  },
];

export {
  CUSTOMER_USER_CREATION_FORM_FIELDS,
  CUSTOMER_USER_UPDATING_FORM_FIELDS,
};
