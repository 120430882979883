import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// API service
import { api, api_file, api_delete } from './api.service';
import { apiCreateTempPassword } from './service/users'; // import the new method
// Components
import PageHeader from './components/page-header';
import Loading from './components/loading';
import Button from './components/button';
import Table from './components/table';
import Confirm from './components/confirm';

const USER_TABLE_COLUMNS = [
  {
    Header: 'Business Name',
    accessor: 'businessName',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Subscription Status',
    accessor: 'stripeSubscriptionStatus',
  },
  {
    Header: 'Has Free Access?',
    accessor: 'hasFreeAccess',
  },
  {
    Header: 'Temp Pass',
    accessor: 'tempPass',
  },
  {
    Header: 'Edit',
    accessor: 'edit',
  },
  {
    Header: 'Delete',
    accessor: 'delete',
  },
];

const EXPORT_TABLE_COLUMNS = [
  'Business Name',
  'Name',
  'Email',
  'Business Type',
  'Project Types',
  'Annual Revenue',
  'Years In Business',
  'Website',
  'Street',
  'City',
  'State',
  'Zip Code',
];

const CellEditUser = ({ editCallback }) => (
  <span className="underline cursor-pointer" onClick={editCallback}>
    Edit
  </span>
);

const CellDeleteUser = ({ deleteCallback }) => (
  <span className="underline cursor-pointer" onClick={deleteCallback}>
    Delete
  </span>
);

const CellTempPass = ({ userId, refreshUsers }) => {
  const handleCreatePass = async () => {
    try {
      const response = await apiCreateTempPassword(userId, '');
      const { tempPass } = response;
      navigator.clipboard.writeText(tempPass);
      refreshUsers();
      toast.success('Copied to clipboard!');
    } catch (error) {
      toast.error('Failed to create temp pass.');
    }
  };

  return (
    <div>
      <button
        onClick={handleCreatePass}
        className="bg-yellow-500  p-1 rounded transition duration-150 ease-in-out transform hover:scale-105 active:scale-95"
      >
        Create Pass
      </button>
    </div>
  );
};

export default function AdminUsers() {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/user/${deleteId}`).then(
      (x) => {
        api(`${process.env.REACT_APP_API_URL}/admin/user`)
          .then((x) => setUsers(x))
          .then((x) => setDeleteId(null));
      },
    );
  };

  const refreshUsers = async () => {
    const response = await api(`${process.env.REACT_APP_API_URL}/admin/user`);
    setUsers(response);
  };

  useEffect(() => {
    refreshUsers();
  }, []);

  if (!users || users.length === 0) return <Loading></Loading>;

  return (
    <>
      <ToastContainer />
      <Confirm
        open={!!deleteId}
        cancel={() => setDeleteId(false)}
        confirm={() => deleteConfirm(deleteId)}
      />
      <PageHeader
        headline={'Users'}
        actions={
          <Button
            text="Add User"
            onClick={() => navigate('/admin/manage-user/new')}
          />
        }
      />
      <div className="flex">
        <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
          <Table
            columns={USER_TABLE_COLUMNS}
            exportColumns={EXPORT_TABLE_COLUMNS}
            exportData={users.map((user) => {
              return {
                businessName: user.businessName ?? '',
                name: user.name ?? '',
                email: user.email ?? '',
                businessType: user.businessType ?? '',
                businessOverview: user.businessOverview ?? '',
                annualRevenue: user.annualRevenue ?? '',
                yearsInBusiness: user.yearsInBusiness ?? '',
                businessWebsite: user.businessWebsite ?? '',
                street: user.street ?? '',
                city: user.city ?? '',
                state: user.state ?? '',
                zipCode: user.zipCode ?? '',
              };
            })}
            data={users.map((user) => {
              return {
                ...user,
                hasFreeAccess: user.hasFreeAccess ? 'Yes' : '-',
                edit: (
                  <CellEditUser
                    key={user._id}
                    editCallback={() =>
                      navigate(`/admin/manage-user/${user._id}`)
                    }
                  />
                ),
                delete: (
                  <CellDeleteUser
                    key={user._id}
                    deleteCallback={() => setDeleteId(user._id)}
                  />
                ),
                tempPass: (
                  <CellTempPass
                    key={user._id}
                    userId={user._id}
                    refreshUsers={refreshUsers}
                  />
                ),
              };
            })}
          />
        </div>
      </div>
    </>
  );
}
