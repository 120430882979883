// PayLaterButton.js
import React, { useState, useEffect } from 'react';
import Button from './button';
import BillFinancingModal from './bill-financing-modal';
import { api } from '../api.service';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function PayLaterButton({ bill, preload = false, className = "" }) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [billData, setBillData] = useState(bill);
    const [hasOffers, setHasOffers] = useState(false);
    const [financingRequest, setFinancingRequest] = useState(null);
    const [creditApplication, setCreditApplication] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasFinancingRequest, setHasFinancingRequest] = useState(bill.financingData.financingRequest !== undefined);
    const [hasPendingOffers, setHasPendingOffers] = useState(false);

    const fetchCreditApplication = async () => {
        try {
            const response = await api(
                `${process.env.REACT_APP_API_URL}/credit-application/application`
            );
            setCreditApplication(response);
            return response;
        } catch (error) {
            console.error('Error fetching credit application:', error);
        }
    };

    const handlePreload = async () => {
        const financingRequest = await fetchFinancingRequest();
        setFinancingRequest(financingRequest);
        console.log("Financing Request", financingRequest);
        const creditApp = await fetchCreditApplication();
        setCreditApplication(creditApp);
        if (financingRequest && financingRequest.error === null) {
            console.log("INSIDE IF");
            setHasFinancingRequest(true);
            setCurrentStep(4); // Offers Step
        }
        checkForPendingOffers(financingRequest);
    };

    const checkForPendingOffers = async (financingRequest) => {
        if (financingRequest?.offers?.length > 0 && financingRequest?.offers != [{ termLength: 0, interestRate: 0, amount: 0 }, { termLength: 0, interestRate: 0, amount: 0 }, { termLength: 0, interestRate: 0, amount: 0 }]) {
            setHasPendingOffers(true);
        }
    };

    useEffect(() => {
        if (preload && bill) {
            handlePreload();
        }
    }, [bill]);

    const fetchFinancingRequest = async () => {
        try {
            const response = await api(
                `${process.env.REACT_APP_API_URL}/financing-request/${bill.financingData.financingRequest}`
            );
            setFinancingRequest(response);
            checkForPendingOffers(response);
            return response;
        } catch (error) {
            console.error('Error fetching financing request:', error);
        }
    };

    const handleClick = async () => {
        setLoading(true);
        const creditApp = await fetchCreditApplication();
        const isBillValid = () => {
            const isBillDataValid = Boolean(bill?.invoiceNumber && bill?.subtotal && (bill?.taxExempt || bill?.tax));
            const isCreditApplicationValid = Boolean(creditApp?.status === 'verified');
            return isBillDataValid && isCreditApplicationValid;
        };
        const financingRequest = await fetchFinancingRequest() || {};

        setLoading(false);

        if (financingRequest?.status === 'Approved') setCurrentStep(5)
        else if (billData.financingData?.financingRequest) setCurrentStep(4); // Offers Step
        else if (isBillValid()) setCurrentStep(2); // ACH Info Step
        else setCurrentStep(1); // Validation Step
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className={`relative inline-block ${className}`}>
                <Button
                    onClick={handleClick}
                    className={`text-xs whitespace-nowrap ${hasPendingOffers ? '' : ''}`}
                    text={hasFinancingRequest ? 'See Offers' : 'Pay Later'}
                />
                {hasPendingOffers && preload && (
                    <span className="absolute -top-1 -right-1 block w-3 h-3 bg-red-600 rounded-full"></span>
                )}
            </div>
            {isOpen && (
                <BillFinancingModal
                    billData={billData}
                    onClose={closeModal}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    setBillData={setBillData}
                    creditApplication={creditApplication}
                    setCreditApplication={setCreditApplication}
                    loading={loading}
                    setLoading={setLoading}
                    financingRequest={financingRequest}
                    setFinancingRequest={setFinancingRequest}
                />
            )}
        </>
    );
}
