import { useEffect, useState } from 'react';
import { api } from './api.service';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserAsCustomer } from './utils';
import Loading from './components/loading';

export default function Pricing() {
  return <PricingComponent />;
}

export const PricingComponent = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserAsCustomer();

      const checkEndingStatus = () => {
        const status = user.stripeSubscriptionStatus;
        if (status?.startsWith('Ending')) {
          const dateString = status.replace('Ending on ', '');
          const endDate = new Date(dateString);
          const currentDate = new Date();

          if (endDate < currentDate) {
            setLoading(false);
          } else {
            navigate('/');
          }
        }
      };

      if (
        user.hasFreeAccess ||
        user.stripeSubscriptionStatus === 'active' ||
        user.stripeSubscriptionStatus === 'trialing'
      ) {
        navigate('/');
      } else if (user.stripeSubscriptionStatus?.startsWith('Ending')) {
        checkEndingStatus();
      } else {
        setLoading(false);
      }
    };

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
      setEmail(x.companyData.email);
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== '/pricing') {
      localStorage.removeItem('token');
      window.location.replace('/');
    }
  }, [location]);

  const handleLogout = () => {
    navigate('/logout');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loading></Loading>
      </div>
    );
  }


  return (
    <div className="relative min-h-screen flex flex-col">
      <button
        onClick={handleLogout}
        className="absolute top-4 right-10 inline-flex items-center px-3 py-2 text-sm font-medium bg-yellow-500 border-2 border-yellow-500 rounded-md text-gray-800 animate-fade-up animate-delay-100"
      >
        Logout
      </button>
      <div className="flex-grow flex justify-center">
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          customer-email={email}
        />
      </div>
    </div>
  );
};
