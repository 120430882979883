// DropdownMenu.js
import React from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

const DropdownMenu = ({
  editMode,
  index,
  dropdownOpen,
  handleSave,
  handleCancel,
  handleDropdownToggle,
  handleEdit,
  handleDelete,
}) => {
  return (
    <div className="pr-4 place-content-center border-b border-slate-500 hidden lg:grid col-auto">
      {editMode === index ? (
        <div className="flex flex-col">
          <button
            onClick={(e) => handleSave(e)}
            className="text-sm bg-yellow-600 hover:bg-yellow-700  w-16 h-6 font-bold rounded-sm -mt-1"
            type="submit"
          >
            Save
          </button>
          <button
            onClick={handleCancel}
            className="text-sm bg-red-500 hover:bg-red-700 text-white w-26 h-6 font-bold rounded-sm mt-1"
            type="button"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="relative dropdown-menu">
          <button
            type="button"
            className="grid place-items-center"
            onClick={() => handleDropdownToggle(index)}
          >
            {/* icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`w-6 transition-transform duration-200 ${dropdownOpen === index ? 'rotate-180' : ''}`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </button>
          {dropdownOpen === index && (
            <div className="z-20 absolute right-0 w-56 mt-2 origin-top-right bg-primary border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none animate-fade animate-duration-100">
              <div className="py-1">
                {/* Edit */}
                <button
                  onClick={() => handleEdit(index)}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <PencilIcon className="w-5 h-5 mr-3" /> Edit
                </button>
                {/* Delete */}
                <button
                  onClick={() => handleDelete(index)}
                  className="flex items-center w-full px-4 py-2 text-sm text-red-700 hover:bg-gray-100"
                  type="button"
                >
                  <TrashIcon className="w-5 h-5 mr-3" /> Delete
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
