export default function TextField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  placeholder,
  onBlur,
  type,
  required,
  disabled = false,
}) {
  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
      >
        {label} {required && <span className="text-rose-600">*</span>}
      </label>
      <div className="mt-1">
        <input
          required={required}
          type={type ? type : 'text'}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`block w-full bg-primary border px-3 p-2 text-gray-800 shadow placeholder:text-gray-600 outline-none text-sm leading-6 ${inputClass} ${disabled ? 'bg-gray-100 cursor-not-allowed border-black' : ''}`}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
