import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import Table from '../components/table';
import { getStatusClass } from '../utils';

const EXPORT_TABLE_COLUMNS = [
    'Date of Creation',
    'Status',
    'Company Name',
    'Contact Info',
];

const CREDIT_APPLICATION_TABLE_COLUMN = [
    {
        Header: 'Date of Creation',
        accessor: 'createdAt',
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
            <span className={`${getStatusClass(value)} capitalize font-bold`}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Company Name',
        accessor: 'companyName',
    },
    {
        Header: 'Contact Info',
        accessor: 'contactInfo',
    },
    {
        Header: 'Actions',
        accessor: 'actions',
    },
];

const CellViewDetails = ({ viewDetailsCallback }) => (
    <span
        className="underline cursor-pointer text-blue-500"
        onClick={viewDetailsCallback}
    >
        View Details
    </span>
);

const CreditApplicationList = () => {
    const [applications, setApplications] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/credit-application`
            );
            setApplications(result);
        } catch (err) {
            setError('Error loading data');
            toast.error('Failed to load credit applications');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;

    // Sort applications by status, with "pending" at the top
    const statusOrder = {
        pending: 1,
        incomplete: 2,
        verified: 3,
        rejected: 4,
    };

    const sortedApplications = [...applications].sort((a, b) => {
        const statusA = statusOrder[a.status?.toLowerCase()] || 5;
        const statusB = statusOrder[b.status?.toLowerCase()] || 5;
        return statusA - statusB;
    });

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Account Applications'} />
            <div className="flex">
                <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
                    <Table
                        columns={CREDIT_APPLICATION_TABLE_COLUMN}
                        exportColumns={EXPORT_TABLE_COLUMNS}
                        data={sortedApplications.map((application) => ({
                            createdAt: new Date(application.createdAt).toLocaleDateString(),
                            status: application.status || 'Unknown',
                            companyName: application.applicationData?.companyLegalName ?? '',
                            contactInfo: application.applicationData?.phoneNumber ?? '',
                            actions: (
                                <CellViewDetails
                                    key={application._id}
                                    viewDetailsCallback={() =>
                                        navigate(
                                            `/admin/credit-application/detail/${application._id}`
                                        )
                                    }
                                />
                            ),
                        }))}
                        exportData={sortedApplications.map((application) => ({
                            'Date of Creation': new Date(application.createdAt).toLocaleDateString(),
                            'Status': application.status || 'Unknown',
                            'Company Name': application.applicationData?.companyLegalName ?? '',
                            'Contact Info': application.applicationData?.phoneNumber ?? '',
                        }))}
                    />
                </div>
            </div>
        </>
    );
};

export default CreditApplicationList;
