export const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
        case 'pending':
            return 'text-yellow-700 font-medium capitalize';
        case 'verified':
            return 'text-green-500 font-medium capitalize';
        case "approved":
            return "text-green-500 font-medium capitalize";
        case 'rejected':
            return 'text-red-500 font-medium capitalize';
        case 'incomplete':
            return 'text-gray-400 font-medium capitalize';
        default:
            return 'text-gray-400 font-medium capitalize';
    }
};