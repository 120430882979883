import React from 'react';
import Modal from '../components/modal';
import BillForm from './bill-form';

/**
 * EmbeddedBillCreationForm
 *
 * Props:
 * - isOpen (boolean): Controls whether the modal is open.
 * - onClose (function): Called when the modal should be closed.
 * - onBillCreated (function): Called when the bill is successfully created,
 *   passing the created bill's values.
 */
export default function EmbeddedBillCreationForm({ isOpen, onClose, onBillCreated }) {
    if (!isOpen) return null;

    // This callback will be passed into BillForm, overriding its default submission handling.
    async function handleBillCreated(values) {
        // 'values' is the full data of the created bill returned by FormWrapper
        if (onBillCreated) {
            onBillCreated(values); // Emit the bill data
        }
        // Close the modal after creation
        onClose();
    }

    return (
        <Modal onClose={onClose} darken={true} containerClassName='w-full max-w-6xl mx-auto p-6 flex flex-col'>
            <BillForm
                id="new"
                // Pass our callback to override the default navigation in handleFormSubmit
                noStyle={true}
                callback={handleBillCreated}
            />
        </Modal>
    );
}
