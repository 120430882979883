import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getUserAsCustomer } from '../utils';
import { toast } from 'react-toastify';
import { api } from '../api.service';
import { usePlaidLink } from 'react-plaid-link';

export default function PlaidButton({
    user,
    text,
    onStatusChange,
    connectionStatus: controlledConnectionStatus, // Optional prop
    setConnectionStatus: controlledSetConnectionStatus, // Optional prop
    simple = false,
}) {
    const isConnectionStatusControlled =
        controlledConnectionStatus !== undefined &&
        controlledSetConnectionStatus !== undefined;

    const [localConnectionStatus, setLocalConnectionStatus] = useState('notConnected');

    const [localUser, setLocalUser] = useState(user);
    const [linkToken, setLinkToken] = useState(null);

    useEffect(() => {
        const fetchUserAndStatus = async () => {
            try {
                let currentUser = localUser;

                if (!currentUser || !currentUser?._id) {
                    currentUser = await getUserAsCustomer();
                    setLocalUser(currentUser);
                }

                if (!currentUser?._id) {
                    throw new Error('User ID not found');
                }

                // Fetch Plaid item to check connection status
                const plaidItem = await getPlaidItem();

                if (plaidItem) {
                    // User has a connected Plaid item
                    updateConnectionStatus('connected');
                } else {
                    // No Plaid item found; user is not connected
                    updateConnectionStatus('notConnected');

                    // Create a new link token for Plaid Link
                    const token = await createLinkToken(currentUser._id);
                    setLinkToken(token);
                }
            } catch (error) {
                console.error('Error fetching user or connection status:', error);
            }
        };
        fetchUserAndStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPlaidItem = async () => {
        try {
            const response = await api(
                `${process.env.REACT_APP_API_URL}/plaid/item`,
                { user_id: localUser._id }
            );
            return response.plaidItem;
        } catch (error) {
            if (error.status === 404) {
                // Plaid item not found, user is not connected
                return null;
            } else {
                console.error('Error fetching Plaid item:', error);
                return null;
            }
        }
    };

    const createLinkToken = async (userId) => {
        try {
            const response = await api(
                `${process.env.REACT_APP_API_URL}/plaid/create-link-token`,
                { user_id: userId }
            );
            return response.link_token;
        } catch (error) {
            console.error('Error creating Plaid link token:', error);
            return null;
        }
    };

    const onSuccess = useCallback(
        async (public_token, metadata) => {
            try {
                await api(`${process.env.REACT_APP_API_URL}/plaid/exchange-token`, {
                    public_token,
                    user_id: localUser._id,
                });
                toast.success('Bank account connected successfully!');
                updateConnectionStatus('connected');
                if (onStatusChange) {
                    onStatusChange('connected');
                }
            } catch (error) {
                console.error('Error exchanging Plaid public token:', error);
                toast.error('Failed to connect bank account. Please try again.');
            }
        },
        [localUser]
    );

    const updateConnectionStatus = (status) => {
        if (isConnectionStatusControlled) {
            controlledSetConnectionStatus(status);
        } else {
            setLocalConnectionStatus(status);
        }
    };

    const getConnectionStatus = () => {
        return isConnectionStatusControlled ? controlledConnectionStatus : localConnectionStatus;
    };

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    const handlePlaidClick = () => {
        open();
    };

    const getStatusIndicator = (status) => {
        switch (status) {
            case 'connected':
                return { colorClass: 'bg-green-500', label: 'Connected' };
            case 'notConnected':
                return { colorClass: 'bg-red-500', label: '' };
            default:
                return { colorClass: 'bg-gray-500', label: '' };
        }
    };

    const { colorClass, label } = getStatusIndicator(getConnectionStatus());

    return (
        <button
            className={`px-1 py-3 rounded-md bg-secondary-100 border shadow-sm border-gray-300 flex items-center justify-start text-xs w-3/4 text-center font-semibold transition-all hover:scale-105 cursor-pointer ${simple ? '' : '!text-xs !px-1'
                }`}
            onClick={() => handlePlaidClick()}
            type="button"
            disabled={!ready}
        >
            <img
                src="/plaid-logo.png"
                alt="Plaid"
                className="w-6 h-6 mr-2 bg-primary rounded-full p-0.5"
            />
            {text || 'Connect Bank Account'}
            <div className="flex items-center ml-auto bg-gray-300 rounded-full px-2 py-1 text-xs">
                <div className={`w-3 h-3 rounded-full mr-1 ${colorClass}`}></div>
                <span className="text-xs">{label}</span>
            </div>
        </button>
    );
}

// Define prop types
PlaidButton.propTypes = {
    user: PropTypes.object.isRequired,
    text: PropTypes.string,
    onStatusChange: PropTypes.func,
    connectionStatus: PropTypes.string, // Optional
    setConnectionStatus: PropTypes.func, // Optional
    simple: PropTypes.bool,
};
