import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FormWrapper from './form-wrapper';
import Button from '../components/button';
import { api } from '../api.service';
import React, { useState, useEffect } from 'react';
import QuickbooksButton from '../components/quickbooks-button';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import PlaidButton from '../components/plaid-button';

export default function ProfileForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const quickbooksEnabled = useFeatureFlagEnabled('quickbooks_button');
  const plaidEnabled = useFeatureFlagEnabled('plaid_button');

  const [checkout, setCheckout] = useState(null);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    if (query.get('success')) {
      setCheckout('success');
    }

    if (query.get('canceled')) {
      setCheckout('canceled');
    }
  }, [query]);

  async function createSubscription() {
    api(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`, {
      lookup_key: process.env.REACT_APP_STRIPE_LOOKUP_KEY,
    }).then((res) => {
      window.location.assign(res.url);
    });
  }

  async function manageSubscription() {
    api(`${process.env.REACT_APP_API_URL}/stripe/create-portal-session`, {
      /* POST REQUEST */
    }).then((res) => {
      window.location.assign(res.url);
    });
  }

  if (checkout === 'success') {
    return <SuccessDisplay onContinue={() => navigate('/')} />;
  } else if (checkout === 'canceled') {
    return (
      <CanceledDisplay
        onSetupSubscription={createSubscription}
        onEditSettings={() => {
          setCheckout(null);
          setQuery({});
        }}
      />
    );
  }

  return (
    <div className="sm:px-4">
      <FormWrapper
        url="app/settings"
        noId={true}
        {...props}
        callback={() => navigate('/')}
        hideButton={true}
      >
        {(values, setValues) => (
          <>
            <h1 className='text-3xl font-bold tracking-tighter text-gray-800'>Settings</h1>
            <div className="mt-8 mb-5 text-2xl font-bold tracking-tighter text-gray-800">
              BuildrFi Subscription Details
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {values.stripeCustomerId ? (
                <>
                  <div className="flex flex-row items-center capitalize">
                    Status:{' '}
                    {/* incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid, or (custom) 'Ending on ddd DD MMM YYYY' */}
                    {values?.stripeSubscriptionStatus.replaceAll('_', ' ') ??
                      'Inactive'}
                  </div>
                  {[
                    'active',
                    'past_due',
                    'unpaid',
                    'incomplete',
                    'trialing',
                  ].includes(values?.stripeSubscriptionStatus) ? (
                    <Button
                      text={'Manage your billing information'}
                      onClick={(e) => {
                        e.preventDefault();
                        manageSubscription();
                      }}
                    />
                  ) : (
                    <Button
                      text={'Restart Subscription'} // 'incomplete_expired', 'canceled', 'Ending...'
                      onClick={(e) => {
                        e.preventDefault();
                        createSubscription();
                      }}
                    />
                  )}
                </>
              ) : (
                <Button
                  text="Setup Subscription"
                  onClick={(e) => {
                    e.preventDefault();
                    createSubscription();
                  }}
                />
              )}
            </div>
            {quickbooksEnabled &&
              <>
                <h1 className='text-2xl font-bold tracking-tighter text-gray-800 mt-6'>Quickbooks</h1>
                <div className="mt-6 flex w-2/3">
                  <QuickbooksButton user={values} text="Manage Quickbooks" />
                </div>
              </>
            }
            {plaidEnabled && (
              <>
                <h1 className="text-2xl font-bold tracking-tighter text-gray-800 mt-6">
                  Bank Account Integration
                </h1>
                <div className="mt-6 flex w-2/3">
                  <PlaidButton user={values} text="Manage Bank Account" />
                </div>
              </>
            )}

          </>
        )}
      </FormWrapper>
    </div>
  );
}

const SuccessDisplay = ({ onContinue }) => {
  return (
    <section className="flex flex-col items-center gap-6 m-6">
      <p>Subscription Confirmed</p>
      <div className="flex flex-row gap-3">
        <Button
          text="Continue to Dashboard"
          onClick={() => window.location.replace('/')}
        />
      </div>
    </section>
  );
};

const CanceledDisplay = ({ onEditSettings, onSetupSubscription }) => (
  <section className="flex flex-col items-center gap-6 m-6">
    <p>
      Order canceled -- You must subscribe to access BuildrFi. You can edit your
      account settings and checkout when ready.
    </p>
    <div className="flex flex-row gap-3">
      <Button text="Edit Settings" onClick={onEditSettings} />
      <Button text="Setup Subscription" onClick={onSetupSubscription} />
    </div>
  </section>
);
