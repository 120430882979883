import { Popover } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export default function HelpPopup({ text }) {
  return (
    <Popover className="absolute inline">
      <Popover.Button>
        <div className="absolute rounded-full -top-2 left-2 bg-neutral-500">
          <InformationCircleIcon className="w-4 h-4 text-white"></InformationCircleIcon>
        </div>
      </Popover.Button>

      <Popover.Panel className="absolute z-40 w-64 p-3 text-sm text-center bg-yellow-300 rounded shadow top-3 -right-10 text-gray-700">
        {text}
      </Popover.Panel>
    </Popover>
  );
}
