import { api } from '../api.service';
import { jwtDecode } from 'jwt-decode';
import { getUser } from "../service/users"

export async function getUserAsCustomer() {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.id;
    const response = await getUser(userId);
    return response
}