import { api, api_delete } from '../api.service';

export const getBusinessAsCustomer = async () => {
  try {
    const response = await api(`${process.env.REACT_APP_API_URL}/app/business`);
    return response;
  } catch (error) {
    console.error('Error fetching business:', error);
    return null;
  }
};