import { CheckIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmailField from './forms/fields/email-field';
import PasswordField from './forms/fields/password-field';
import SelectField from './forms/fields/select-field';
import TextField from './forms/fields/text-field';
import logo from './logo.png';

export default function Register() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        user: values,
        metaData: { ref: searchParams.get('r'), client: searchParams.get('c') },
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((x) => {
        if (x.error) {
          if (x.error == 'email_in_use') {
            setError(
              'Email already in use. Please sign in or register with a different email.',
            );
          }
        } else if (x.token) {
          localStorage.setItem('token', x.token);
          if (searchParams.get('rd')) {
            window.location.replace(searchParams.get('rd'));
          } else {
            window.location.replace('/');
          }
        } else {
          setError(
            'Registration failed. Please check your details and try again.',
          );
        }
      });
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="hidden max-w-md lg:flex bg-secondary">
          <div className="flex flex-col px-20 m-auto">
            <img
              className="relative z-10 w-64 h-auto mx-auto mb-14"
              src={logo}
            />
            <div className="mb-5 text-3xl font-semibold leading-10 text-gray-800">
              Let's start building together in minutes!
            </div>
            <div className="flex items-center text-gray-600">
              <div className="p-px my-3 mr-2 text-white bg-yellow-500 rounded-full">
                <CheckIcon className="w-4" />
              </div>
              Manage your project cash flows
            </div>
            <div className="flex items-center text-gray-600">
              <div className="p-px my-3 mr-2 text-white bg-yellow-500 rounded-full">
                <CheckIcon className="w-4" />
              </div>
              Access Financing
            </div>
            <div className="flex items-center text-gray-600">
              <div className="p-px my-3 mr-2 text-white bg-yellow-500 rounded-full">
                <CheckIcon className="w-4" />
              </div>
              Grow your business
            </div>

            <div className="mt-5 text-lg font-medium text-gray-700">
              An all-in-one financial platform for construction
            </div>
          </div>
        </div>
        <div className="flex flex-1 p-5 bg-primary">
          <form
            className="w-full max-w-xl pb-10 m-auto"
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <img src={logo} className="mx-auto mt-auto mb-10 lg:hidden w-80" />
            <div className="text-4xl font-medium text-gray-800">
              Get Started!
            </div>
            <div>
              <label className="block mt-5 text-sm font-medium text-left text-gray-800">
                {error}
              </label>
            </div>
            <TextField
              required
              label="First Name"
              value={values.firstName}
              onChange={(val) => setValues({ ...values, firstName: val })}
            ></TextField>
            <TextField
              required
              label="Last Name"
              value={values.lastName}
              onChange={(val) => setValues({ ...values, lastName: val })}
            ></TextField>
            <TextField
              required
              label="Legal Business Name"
              value={values.businessName}
              onChange={(val) => setValues({ ...values, businessName: val })}
            ></TextField>
            <EmailField
              required
              label="Email"
              value={values.email}
              onChange={(val) => setValues({ ...values, email: val })}
            ></EmailField>
            <PasswordField
              required
              label="Password"
              value={values.password}
              onChange={(val) => setValues({ ...values, password: val })}
            ></PasswordField>
            <PasswordField
              required
              label="Confirm Password"
              value={values.confirmPassword}
              onChange={(val) => setValues({ ...values, confirmPassword: val })}
            ></PasswordField>
            <SelectField
              required
              label="Company Type"
              optionListName={'company-types'}
              value={values.businessType}
              onChange={(val) => setValues({ ...values, businessType: val })}
            />
            <div className="flex mt-3">
              <input
                required
                checked={values.tos}
                onChange={(e) =>
                  setValues({ ...values, tos: e.target.checked })
                }
                type="checkbox"
                className="block appearance-none rounded border mt-0.5 border-gray-200  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
              />

              <div className="ml-2 text-sm text-light text-gray-500">
                I accept the platform{' '}
                <a
                  target="_blank"
                  href="https://www.buildrfi.com/terms-of-use"
                  className="underline text-brand-400 hover:text-brand-500"
                >
                  Terms of Use
                </a>
              </div>
            </div>

            <button
              type="submit"
              className="flex justify-center w-full px-4 py-2 mt-5 font-semibold bg-yellow-500 border-2 border-yellow-500 rounded text-gray-800 disabled:border-gray-400 disabled:bg-gray-400 disabled:text-gray-300 text-md hover:bg-yellow-600 "
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
