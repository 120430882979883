import CurrencyInput from 'react-currency-input-field';

export default function CurrencyField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  onBlur,
  required,
  min,
  max,
  step,
  disabled = false,
}) {
  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
      >
        {label} {required && <span className="text-rose-600">*</span>}
      </label>
      <div className="relative w-full mt-1 rounded-l-md flex flex-row">
        <div className="left-0 flex items-center p-2 border rounded-l-md pointer-events-none border-gray-200 h-12 border-r-0">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <CurrencyInput
          decimalScale={2}
          required={required}
          onBlur={onBlur}
          value={value}
          onValueChange={(value, name) => {
            onChange(value);
          }}
          min={min}
          max={max}
          step={step ?? '0.01'}
          className={` bg-primary w-full border-none py-2 text-gray-800  placeholder:text-gray-600 outline-none sm:text-sm sm:leading-6 ${inputClass} ${disabled ? '!bg-gray-100 cursor-not-allowed' : ''}`}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
