/**
 * Sort function for array elements.
 *
 * @param {Object} a - The first item to compare.
 * @param {Object} b - The second item to compare.
 * @param {Object} options - Sorting options.
 * @param {string|function} options.accessor - The property or accessor function to sort by.
 * @param {boolean} options.isAscending - Indicates if the sorting should be in ascending order.
 * @returns {number} - A negative value if a < b, zero if a === b, or a positive value if a > b.
 */

export function sortFn(a, b, { accessor, isAscending }) {
  if (typeof accessor === 'function') {
    a = accessor(a);
    b = accessor(b);
  } else {
    a = a[accessor];
    b = b[accessor];
  }

  if (a === undefined) return b === undefined ? 0 : 1;
  if (b === undefined) return -1;

  return (a > b ? 1 : -1) * (isAscending ? -1 : 1);
}
/*
 * @example
 * // Example data
 * const data = [
 * { name: 'Alice', age: 30 },
 * { name: 'Bob', age: 25 },
 * { name: 'Collin', age: 35 }
 * ];
 *
 * // Sort by name in ascending order
 * data.sort((a, b) => sortFn(a, b, { accessor: 'name', isAscending: true }));
 * // Output: [
 * //   { name: 'Alice', age: 30 },
 * //   { name: 'Bob', age: 25 },
 * //   { name: 'Collin', age: 35 }
 * // ]
 *
 * // Sort by age in descending order
 * data.sort((a, b) => sortFn(a, b, { accessor: 'age', isAscending: false }));
 * // Output: [
 * //   { name: 'Collin', age: 35 },
 * //   { name: 'Alice', age: 30 },
 * //   { name: 'Bob', age: 25 }
 * // ]
 */
