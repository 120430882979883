// Imports
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import Table from '../components/table';
import { getStatusClass } from '../utils';

const EXPORT_TABLE_COLUMNS = [
    'Name',
    'Location',
    'Customer',
    'Start Date',
    'Status',
];

const PROJECTS_TABLE_COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Location',
        accessor: 'location',
    },
    {
        Header: 'Customer',
        accessor: 'customer',
    },
    {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'N/A'),
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
            <span className={`${getStatusClass(value)} capitalize font-bold`}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Actions',
        accessor: 'actions',
    },
];

const CellViewDetails = ({ viewDetailsCallback }) => (
    <span
        className="underline cursor-pointer text-blue-500"
        onClick={viewDetailsCallback}
    >
        View Details
    </span>
);

const AdminProjectsList = () => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/project`
            );
            setProjects(result);
        } catch (err) {
            setError('Error loading data');
            toast.error('Failed to load projects');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;

    // Sort projects by status if needed
    const statusOrder = {
        pending: 1,
        active: 2,
        completed: 3,
        archived: 4,
    };

    const sortedProjects = [...projects].sort((a, b) => {
        const statusA = statusOrder[a.status?.toLowerCase()] || 5;
        const statusB = statusOrder[b.status?.toLowerCase()] || 5;
        return statusA - statusB;
    });

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Projects Management'} />
            <div className="flex">
                <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
                    <Table
                        columns={PROJECTS_TABLE_COLUMNS}
                        exportColumns={EXPORT_TABLE_COLUMNS}
                        data={sortedProjects.map((project) => ({
                            name: project.name || 'N/A',
                            location: project.location || 'N/A',
                            customer: project.customer || 'N/A',
                            startDate: project.startDate,
                            status: project.status || 'Unknown',
                            actions: (
                                <CellViewDetails
                                    key={project._id}
                                    viewDetailsCallback={() =>
                                        navigate(
                                            `/admin/project/detail/${project._id}`
                                        )
                                    }
                                />
                            ),
                        }))}
                        exportData={sortedProjects.map((project) => ({
                            'Name': project.name || 'N/A',
                            'Location': project.location || 'N/A',
                            'Customer': project.customer || 'N/A',
                            'Start Date': project.startDate
                                ? new Date(project.startDate).toLocaleDateString()
                                : 'N/A',
                            'Status': project.status || 'Unknown',
                        }))}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminProjectsList;
