import React from 'react';
import { useNavigate } from 'react-router-dom';

const AdminProjectsTable = ({ projects, className }) => {
    const navigate = useNavigate();

    if (!projects || projects.length === 0) {
        return <p className="px-4">No projects found.</p>;
    }

    return (
        <div className={`overflow-x-auto ${className}`}>
            <table className="min-w-full bg-primary">
                <thead>
                    <tr>
                        <th className="py-2 px-4 bg-gray-200 text-left">Name</th>
                        <th className="py-2 px-4 bg-gray-200 text-left">Location</th>
                        <th className="py-2 px-4 bg-gray-200 text-left">Start Date</th>
                        <th className="py-2 px-4 bg-gray-200 text-left">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map((project, index) => (
                        <tr
                            key={project._id}
                            className={`cursor-pointer odd:bg-gray-100 z-20 hover:bg-blue-100 transition-all`}
                            onClick={() => navigate(`/admin/project/detail/${project._id}`)}
                        >
                            <td className="border py-2 px-4">{project.name || 'N/A'}</td>
                            <td className="border py-2 px-4">{project.location || 'N/A'}</td>
                            <td className="border py-2 px-4">
                                {project.startDate
                                    ? new Date(project.startDate).toLocaleDateString()
                                    : 'N/A'}
                            </td>
                            <td className="border py-2 px-4">
                                <span className={`capitalize font-bold`}>
                                    {project.status || 'Unknown'}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminProjectsTable;
