//admin dashboard with tremor to show some stats, small tables and charts
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';

export default function AdminDashboard() {

    return (
        <div>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Admin Dashboard'} />
        </div>
    )
}